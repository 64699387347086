import React from "react";
import Helmet from "react-helmet";
import Header from "../components/layouts/header";
import { graphql, Link } from "gatsby";
import { PrimaryLink } from "../components/PrimaryButton";
function Index({ data }) {
  const {
    contentfulRole: {
      pageTitle,
      metaDescription,
      title,
      description,
      location,
      duration,
      salary,
      startDate,
      buttonText,
      buttonLink
    }
  } = data;

  return (
    <>
      <Helmet>
        <title>Careers | {pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content={`Ratio, fintech, finance, software, careers at Ratio, careers, jobs, roles, job roles, job search, job listing, search jobs, career, employment, work, find jobs, ${pageTitle} careers`}
        />
      </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
        </div>
      </div>

      <div className="section-py">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <Link to="/careers/" className="btn btn-lg btn-outline-dark mb-5">
                {"←"} Back
              </Link>
              <h2 className="text-primary h1 mb-3 mb-md-5">{title}</h2>
              <div className="my-md-5 my-3 d-flex flex-wrap">
                <div>
                  <strong className="text-primary text-uppercase mr-2">
                    {" "}
                    Location:
                  </strong>
                    {location}
                </div>
                <div>
                  <strong className="text-primary text-uppercase mr-2 ml-md-3">
                    {" "}
                    Salary:
                  </strong>
                  {salary}
                </div>
                <div>
                  {" "}
                  <strong className="text-primary text-uppercase mr-2 ml-md-3">
                    {" "}
                    Start date:
                  </strong>
                  {startDate}
                </div>
                <div>
                  {" "}
                  <strong className="text-primary text-uppercase mr-2 ml-md-3">
                    {" "}
                    Duration:
                  </strong>
                    {duration}
                </div>
              </div>{" "}
              <div
                className="mb-5"
                dangerouslySetInnerHTML={{ __html: description.md.html }}
              />
              {buttonLink && buttonText && (
                <PrimaryLink external to={buttonLink} text={buttonText} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;

export const query = graphql`
  query($slug: String) {
    contentfulRole(slug: { eq: $slug }) {
      pageTitle: title
      metaDescription
      slug
      title
      location
      duration
      category {
        title
      }
      buttonText
      buttonLink
      duration
      salary
      startDate
      description {
        md: childMarkdownRemark {
          html
        }
      }
    }
  }
`;
